.contact {
    padding: 50px 20px;
    background-color: #0e0b16;
    color: white;
    text-align: center;
}

.contact h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.contact form {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
}

.contact input,
.contact textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: #1a1a2e;
    color: white;
    font-size: 1rem;
    box-sizing: border-box;
}

.contact input::placeholder,
.contact textarea::placeholder {
    color: #c5c5c5;
}

.contact textarea {
    min-height: 150px;
    resize: vertical;
}

.submit-button {
    position: relative;
    padding: 15px 30px;
    background-color: #3b3a5a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
}

.submit-button:hover:not(:disabled) {
    background-color: #565482;
}

.submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.submit-button .button-text {
    position: relative;
    z-index: 1;
}

.submit-button .button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-top: 2px solid transparent;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.submit-button.submitting .button-icon {
    opacity: 1;
    animation: spin 1s linear infinite;
}

.submit-button.submitting .button-text {
    opacity: 0;
}

.submit-button.success {
    background-color: #4CAF50;
}

.submit-button.error {
    background-color: #f44336;
}

.status-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.status-message.success {
    background-color: rgba(76, 175, 80, 0.1);
    color: #4CAF50;
}

.status-message.error {
    background-color: rgba(244, 67, 54, 0.1);
    color: #f44336;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@media (max-width: 768px) {
    .contact h2 {
        font-size: 2rem;
    }

    .contact input,
    .contact textarea {
        padding: 12px;
        font-size: 0.9rem;
    }

    .submit-button {
        padding: 12px 25px;
    }

    .contact {
        padding: 40px 15px;
    }
}

@media (max-width: 480px) {
    .contact h2 {
        font-size: 1.75rem;
    }

    .contact input,
    .contact textarea {
        font-size: 0.85rem;
        padding: 10px;
    }

    .submit-button {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}