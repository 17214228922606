.orbit-container {
    position: relative;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: transparent;
}

.sun {
    position: absolute;
    width: 40px;
    height: 40px;
    background: radial-gradient(circle, #ffff00 0%, #ff9900 100%);
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(255, 255, 0, 0.6);
}

.orbit {
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    animation: rotate linear infinite;
}

.planet {
    position: absolute;
    border-radius: 50%;
    transform: translateX(-50%);
}

.mercury-orbit {
    width: 60px;
    height: 60px;
    animation-duration: 5s;
}

.mercury {
    width: 4px;
    height: 4px;
    background-color: #8c7e6d;
    top: -2px;
    left: 50%;
}

.venus-orbit {
    width: 90px;
    height: 90px;
    animation-duration: 8s;
}

.venus {
    width: 6px;
    height: 6px;
    background-color: #e6b87c;
    top: -3px;
    left: 50%;
}

.earth-orbit {
    width: 120px;
    height: 120px;
    animation-duration: 12s;
}

.earth {
    width: 7px;
    height: 7px;
    background-color: #6b93d6;
    top: -3.5px;
    left: 50%;
}

.moon-orbit {
    position: absolute;
    top: -10px;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    animation: rotate 3s linear infinite;
}

.moon {
    position: absolute;
    top: -1px;
    left: 50%;
    width: 2px;
    height: 2px;
    background-color: #d0d0d0;
    border-radius: 50%;
    transform: translateX(-50%);
}

.mars-orbit {
    width: 150px;
    height: 150px;
    animation-duration: 20s;
}

.mars {
    width: 5px;
    height: 5px;
    background-color: #c1440e;
    top: -2.5px;
    left: 50%;
}

.jupiter-orbit {
    width: 200px;
    height: 200px;
    animation-duration: 30s;
}

.jupiter {
    width: 14px;
    height: 14px;
    background: radial-gradient(circle, #e0ae6f 60%, #8b6220 100%);
    top: -7px;
    left: 50%;
}

.saturn-orbit {
    width: 250px;
    height: 250px;
    animation-duration: 40s;
}

.saturn {
    width: 12px;
    height: 12px;
    background: radial-gradient(circle, #f0e4b5 60%, #c5aa6a 100%);
    top: -6px;
    left: 50%;
}

.saturn-ring {
    position: absolute;
    width: 24px;
    height: 4px;
    background-color: rgba(240, 228, 181, 0.7);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(20deg);
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}





/* Responsive adjustments */
@media (max-width: 768px) {
    .orbit-container {
        transform: scale(0.9);
    }
}

@media (max-width: 480px) {
    .orbit-container {
        transform: scale(0.9);
        margin-bottom: 1.2rem;
    }
    
}

@media (max-height: 600px) {
    .orbit-container {
        transform: scale(0.8);
        margin-bottom: 1.2rem;
    }
    
}

/* Ensure the container takes full viewport height on mobile */
@media (max-width: 768px) and (max-height: 600px) {
    .orbit-container {
        height: 100vh;
    }
}