.footer-container {
    background-color: #0e0b16;
    color: white;
    padding: 20px 20px 40px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

.footer-wave {
    width: 100%;
    height: 100px;
    overflow: hidden;
    line-height: 0;
    position: relative;
    margin-bottom: -5px;
    /* Adjust this value to close the gap between content and footer */
}

.footer-wave svg {
    display: block;
    width: 100%;
    height: 100px;
    position: relative;
    top: -5px;
    /* Adjust this value for smoother transition */
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
}

.footer-about {
    margin-bottom: 30px;
}

.footer-about h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.footer-about p {
    color: #c4c4c4;
    font-size: 1.1rem;
}

.footer-links ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.footer-links ul li {
    margin: 0 15px;
}

.footer-links ul li a {
    color: #39ff14;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 1.1rem;
}

.footer-links ul li a:hover {
    color: #28cc0e;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.social-icons a {
    color: white;
    margin: 0 15px;
    font-size: 1.8rem;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #39ff14;
}

.footer-bottom {
    font-size: 0.9rem;
    border-top: 1px solid #c4c4c4;
    padding-top: 20px;
}

.scroll-to-top {
    display: block;
    margin: 20px auto 0;
    font-size: 2rem;
    color: #39ff14;
    text-decoration: none;
    transition: color 0.3s ease;
}

.scroll-to-top:hover {
    color: #28cc0e;
}

.logo_footer{
    width: 10%;
}

@media screen and (max-width: 768px) {
    .footer-links ul {
        flex-direction: column;
    }

    .footer-links ul li {
        margin: 10px 0;
    }

    .social-icons {
        flex-direction: column;
    }

    .social-icons a {
        margin: 10px 0;
    }
}