.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.star {
  position: absolute;
  background-color: white;
  border-radius: 10%;
  animation: twinkle 2s infinite ease-in-out;
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.main-content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Estilos para la pantalla de contraseña */
.password-container {
  min-height: 100vh;
  background: linear-gradient(45deg, #ffd6d6, #ffb3b3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.love-message {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.password-input {
  padding: 12px 20px;
  margin: 15px 0;
  border: 2px solid #ff6b6b;
  border-radius: 30px;
  font-size: 1.1rem;
  width: 300px;
  text-align: center;
}

.love-button {
  background: #ff6b6b;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.love-button:hover {
  transform: scale(1.05);
}

.error-message {
  color: #ff4444;
  margin-top: 10px;
}

.hearts-falling {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.heart {
  position: absolute;
  animation: fall 8s linear infinite;
  opacity: 0.7;
}

@keyframes fall {
  0% {
    transform: translateY(-100%) rotate(0deg);
  }

  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}
