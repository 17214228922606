/* Navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.navbar {
    background-color: #000211;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.logo{
    width: 40px;
    margin: 0 auto;
}

.navbar-scrolled {
    background-color: rgba(103, 102, 102, 0.8);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.navbar-logo {
    color: #fff;
    font-size: 24px;
    text-decoration: none;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.navbar-logo i {
    margin-left: 10px;
    color: #39ff14;
}

.navbar-menu {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
}

.navbar-item {
    margin: 0;
}

.navbar-link {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-link:hover {
    color: #39ff14;
    transform: scale(1.05);
}

.navbar-item.active .navbar-link {
    color: #39ff14;
    font-weight: 700;
}

.navbar-hamburger {
    display: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

/* Tablet and Mobile View */
@media (max-width: 768px) {
    .navbar-menu {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        background-color: #000211;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px 0;
        transform: translateY(-100%);
        visibility: hidden;
        opacity: 0;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
        
    }

    .navbar-menu.open {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    }

    .navbar-hamburger {
        display: block;
    }
}

/* Small Mobile View */
@media (max-width: 480px) {
    .navbar-logo {
        font-size: 20px;
    }

    .navbar-link {
        font-size: 16px;
    }
}