.profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: auto;
    margin: 0 auto;
}

.profile-image-wrapper {
    flex-shrink: 0;
    transition: transform 0.5s ease-in-out;
    position: relative;
}

.profile-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    transition: transform 0.5s ease-in-out;
}

.profile-description {
    position: absolute;
    left: 50%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    padding-left: 20px;
    width: 30%;
    color: white;
    font-size: 1.2rem;
    z-index: 10;
}

.profile-container:hover .profile-image-wrapper {
    transform: translateX(-150px);
}

.profile-container:hover .profile-description {
    opacity: 1;
}

.profile-description h3 {
    font-size: 2rem;
    margin-bottom: 15px;
}

.profile-description p {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #f0f0f0;
}

.fade-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(14, 11, 22,0) 0%, rgba(14, 11, 22,1) 100%);
    pointer-events: none;
}

/* Medium devices (e.g., tablets) */
@media screen and (max-width: 1024px) {
    .profile-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .profile-image-wrapper {
        max-width: 80%;
        margin-bottom: 20px;
    }

    .profile-description {
        position: static;
        opacity: 1;
        width: 80%;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 20px;
        font-size: 1.1rem;
    }

    .fade-bottom {
        display: block;
    }

    /* Adjust hover effects for medium devices */
    .profile-container:hover .profile-image-wrapper {
        transform: none;
    }

    .profile-container:hover .profile-description {
        opacity: 1;
    }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
    .profile-container {
        flex-direction: column;
    }

    .profile-image-wrapper {
        max-width: 100%;
    }

    .profile-image {
        max-width: 50%;
        transform: none;
    }

    .profile-description {
        position: static;
        opacity: 1;
        width: 80%;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    

    .profile-container:hover .profile-image-wrapper {
        transform: none;
    }

    .profile-container:hover .profile-description {
        opacity: 1;
    }
}
