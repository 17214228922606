.journal-container {
  background: linear-gradient(45deg, #fff5f5, #ffe6e6);
  min-height: 100vh;
  padding: 2rem;
}

.journal-header {
  text-align: center;
  padding: 4rem 0;
  position: relative;
}

.heart-animation {
  animation: heartbeat 1.5s infinite;
  font-size: 3rem;
}

@keyframes heartbeat {
  0% { transform: scale(1); }
  15% { transform: scale(1.3); }
  30% { transform: scale(1); }
  45% { transform: scale(1.15); }
  60% { transform: scale(1); }
}

.timeline {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  padding: 2rem 0;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #ff6b6b;
  transform: translateX(-50%);
}

.journal-entry {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease-out;
  margin: 3rem 0;
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  position: relative;
}

.journal-entry.visible {
  opacity: 1;
  transform: translateY(0);
}

.entry-date {
  font-family: 'Playfair Display', serif;
  color: #ff6b6b;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.entry-content {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.entry-text {
  flex: 1;
  line-height: 1.6;
  font-size: 1.1rem;
}

.entry-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.entry-image:hover {
  transform: scale(1.05);
}

.end-message {
  text-align: center;
  padding: 5rem 0;
  font-style: italic;
  color: #666;
}

.loading-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ff6b6b;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.journal-entry {
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease;
}

.journal-entry.visible {
  opacity: 1;
  transform: translateY(0);
}

.entry-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 1rem;
}